const getCRSF = () => {
  return document.querySelector('meta[name="csrf-token"]').content
}

export default function CSRFFetch (url, options = {}) {
  options.headers = options.headers || {}
  options.headers['X-Requested-With'] = 'XMLHttpRequest'
  options.headers['X-CSRF-TOKEN'] = getCRSF()
  options.credentials = 'same-origin'

  return fetch(url, options)
}
